<template>

  <div id="app" :class="{'show-sidebar': showSidebar}">

    <div class="sidebar">
      <div style="color: white; font-size: 20px" class="d-flex justify-content-center align-items-center mt-3 mb-3">
        <i class="fa fa-stethoscope" style="font-size: 1.5em" aria-hidden="true"/>
        <span class="ml-2">E-CARE</span>
      </div>
      <main-menu/>
    </div>

    <android-like-sidebar v-model="showMobileMenu">
      <div class="m-3 align-self-start d-flex align-items-center" style="color: white; font-size: 16px">
        <i v-if="user.avatarUrl === null" class="fa fa-fw fa-user-circle mr-2" style="font-size: 2em;"></i>
        <img :src="user.avatarUrl" v-if="user.avatarUrl !== null" class="rounded-circle user-picture mr-2" alt="User avatar">
        <span class="mr-1">{{user.displayName}}</span>
      </div>
      <main-menu @link-clicked="() => showMobileMenu = false"/>
    </android-like-sidebar>

    <div class="content">

      <div class="container-fluid">

        <div class="row topbar-container">

          <!-- Mobile bar-->
          <AndroidLikeTopbar @toggleSidebar="() => showMobileMenu = !showMobileMenu" />

          <div class="col-12 align-items-center pr-0 topbar desktop-topbar">
            <!-- Desktop bar -->
            <a class="toggle-sidebar hand-on-hover" @click="toggleSidebar">
              <i class="fa fa-bars" :class="{'fa-rotate-270': !showSidebar}" />
            </a>
            <b-dd right class="mr-0 height-100 hover ml-auto" no-caret toggle-class="text-decoration-none" variant="link">
              <template #button-content>
                <div class="d-flex align-items-center mr-2" style="color: #73879C;">
                  <i v-if="user.avatarUrl === null" class="fa fa-fw fa-user-circle mr-2" style="font-size: 2.5em;"></i>
                  <img :src="user.avatarUrl" v-if="user.avatarUrl !== null" class="rounded-circle user-picture mr-2" alt="User avatar">
                  <span class="mr-1">{{user.displayName}}</span>
                  <i class="fa fa-angle-down"/>
                </div>
              </template>
              <b-dropdown-item @click="showSettings = true"><i class="fa fa-fw fa-sliders"/> Preferences</b-dropdown-item>
              <b-dropdown-item v-if="$can('changePassword')" @click="showPwChange = true">
                <i class="fa fa-fw fa-key"/> Change password
              </b-dropdown-item>
              <b-dropdown-item @click="logout"><i class="fa fa-fw fa-sign-out"/> Logout</b-dropdown-item>
            </b-dd>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <router-view/>
          </div>
        </div>

        <footer class="row">
          <div class="col-12">
            <div class="text-muted font-italic small text-right border-top">TZ: {{$state.timezone}}; E-CARE v{{$options.APP_VERSION}}</div>
          </div>
        </footer>

      </div>

    </div>

    <b-modal centered v-model="showSettings" title="Preferences">
      <settings ref="settings"/>
      <template slot="modal-footer" slot-scope="{ ok, cancel, hide }">
        <b-button variant="outline-secondary" @click="cancel()">Cancel</b-button>
        <busy-btn variant="primary" @click="save(ok)">Save</busy-btn>
      </template>
    </b-modal>

    <password-change-modal v-model="showPwChange"/>

  </div>

</template>

<script>
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';

import AndroidLikeSidebar  from '@/components/AndroidLikeSidebar';
import MainMenu            from './components/MainMenu.vue';
import PasswordChangeModal from './components/PasswordChangeModal';
import Settings            from './Main/Settings';
import {CACHE_FIRST}       from './lib/constants';
import AndroidLikeTopbar   from '@/components/AndroidLikeTopbar';
import store2              from '@/lib/store2';

const MOBILE_THRESHOLD = 576;

export default
{
    apollo:
    {
        user:
        {
            query: gql`{ user:whoAmI { avatarUrl id email displayName } }`,
            fetchPolicy: CACHE_FIRST
        }
    },
    beforeDestroy()
    {
        window.removeEventListener('resize', this.resizeEvent);
    },
    components: {AndroidLikeTopbar, AndroidLikeSidebar, PasswordChangeModal, Settings, MainMenu },
    data()
    {
        return {
            user:
            {
                avatarUrl: null,
                displayName: '...'
            },
            showPwChange: false,
            showSettings: false,
            showMobileMenu: false,
            showSidebar: window.innerWidth > MOBILE_THRESHOLD,
            lastSidebarStatus: window.innerWidth > MOBILE_THRESHOLD,
        }
    },
    name: 'app',
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    methods:
    {
        logout()
        {
            this.$router.replace('/logout');
        },
        toggleSidebar()
        {
            this.showSidebar = !this.showSidebar;
            this.lastSidebarStatus = this.showSidebar;
        },
        async save(ok)
        {
            await this.$refs.settings.save();
            await ok();
        },
        resizeEvent: debounce(
            function(e)
            {
                if(window.innerWidth < MOBILE_THRESHOLD)
                    this.showSidebar = false;
                else
                    this.showSidebar = this.lastSidebarStatus;
            },
            200
        )
    },
    mounted()
    {
        window.addEventListener('resize', this.resizeEvent);
    },
    APP_VERSION: APP_VERSION
}
</script>

<style scoped>

div.sidebar
{
  width: 256px;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #2A3F54;
  z-index: 20;
  left: -256px;
  transition: left 200ms;
  overflow-y: auto;
}

div.content
{
  z-index: 10;
  transition: padding-left 200ms, left 200ms, right 200ms;
}

img.user-picture
{
    width: 2em;
    height: 2em;
}

@media(min-width:576px)
{
    div.show-sidebar > div.sidebar
    {
        left: 0;
    }

    div.show-sidebar > div.content
    {
        padding-left: 256px;
    }

    img.user-picture
    {
        width: 2.5em;
        height: 2.5em;
    }
}

.hover:hover
{
  background-color: rgb(217, 222, 228);
}

.toggle-sidebar
{
    color: rgb(115, 135, 156);
}

.toggle-sidebar:hover
{
  color: rgb(35, 82, 124) !important;
}
</style>

