<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item active>Integrations</b-breadcrumb-item>
      <b-breadcrumb-item active>ISO/TS 15143-3 (AEMP 2.0)</b-breadcrumb-item>
    </b-breadcrumb>
    <div class="panel">
      <div class="panel-heading">
        <h3>ISO/TS 15143-3 (AEMP 2.0)</h3>
      </div>
      <div class="panel-content">
        <h4>Table of contents</h4>
          <ol>
            <li><a href="#authentication">Authentication</a></li>
            <li><a href="#responseFormats">Response formats</a></li>
            <li><a href="#endpoints">Endpoints</a></li>
            <li><a href="#fieldDefinitions">Field definitions</a></li>
            <li><a href="#endpointDocumentation">Per endpoint specific documentation</a>
              <ol>
                <li><a href="#fleetOverview">Fleet overview</a></li>
                <li><a href="#singleElementSnapshot">Single element snapshot</a></li>
                <li><a href="#timeSeries">Time series</a></li>
                <li><a href="#timeSeriesField">Time series field</a></li>
              </ol>
            </li>
          </ol>

        <h4 id="authentication">Authentication</h4>
          <h5>API key</h5>
            <p>To use an API key, go to <b-link to="/app/apiKeys">API keys</b-link> and generate a new API key</p>
            <p>You can enter your API key here to complete the examples: <b-input v-model="apiKey" class="d-inline-block w-25" /></p>
          <h5>HTTP header</h5>
            <p>The preferred way is to pass the API key using the
              <b-link href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization" target="_blank">HTTP Authorization header</b-link>.
            </p>
            <p><code>curl -H "Authorization: ApiKey {{apiKey}}" {{apiRoot}}/Fleet</code></p>
          <h5>GET request parameter</h5>
            <p>Alternatively the API key can be passed as the GET parameter <code>apiKey</code>.</p>
            <p><code>curl {{apiRoot}}/Fleet?apiKey={{apiKey}}</code></p>
            <p>Due to the sensitivity of the API key, any links in the responses will not contain the API key. You have to add the parameter back in yourself.</p>
        <h4 id="responseFormats">Response formats</h4>
          <p>The default response format is UTF-8 encoded XML.</p>
        <h4 id="endpoints">Endpoints</h4>
          <b-table-simple>
            <b-thead>
              <b-th>Description</b-th>
              <b-th>E-Crane</b-th>
              <b-th>URL (opens in new window)</b-th>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>Fleet snapshot</b-td>
                <b-td>All</b-td>
                <b-td><b-link to="/api/v1/iso15143/Fleet" target="_blank">{{apiRoot}}/Fleet</b-link></b-td>
              </b-tr>
              <template v-if="loading">
                <b-tr>
                  <b-td colspan="3" class="text-center">Loading...</b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr>
                  <b-td :rowspan="Math.max(2, devices.nodes.length + 1)">Single-element snapshot</b-td>
                </b-tr>
                <b-tr v-if="devices.nodes.length === 0">
                  <td>-</td>
                  <td>The API needs to be enabled per E-Crane. Please contact service@e-crane.com and ask for "IT307-001 ISO 15143-3:2020 (AEMP 2.0) enablement for E-CARE"</td>
                </b-tr>
                <b-tr v-for="device in devices.nodes" :key="`${device.id}_snap`">
                  <b-td>{{device.displayName}}</b-td>
                  <b-td>
                    <b-link :to="`${device.iso15143SnapshotUrl}`" target="_blank">{{root}}{{device.iso15143SnapshotUrl}}</b-link>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td :rowspan="Math.max(2, devices.nodes.length + 1)">Time series</b-td>
                </b-tr>
                <b-tr v-if="devices.nodes.length === 0">
                  <td>-</td>
                  <td>The API needs to be enabled per E-Crane. Please contact service@e-crane.com and ask for "IT307-001 ISO 15143-3:2020 (AEMP 2.0) enablement for E-CARE"</td>
                </b-tr>
                <b-tr v-for="device in devices.nodes" :key="`${device.id}_ts`">
                  <b-td>{{device.displayName}}</b-td>
                  <b-td>
                    <b-link :to="`${device.iso15143SnapshotUrl}/${tsString}`" target="_blank">{{root}}{{device.iso15143SnapshotUrl}}/{{tsString}}</b-link>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        <h4 id="fieldDefinitions">Field definitions</h4>
        <h5>ISO standard</h5>
          <dl class="row">
            <dt class="col-sm-2">CumulativeIdleHours</dt>
            <dd class="col-sm-9">Time where the main motor was on, but there was no joystick input</dd>

            <dt class="col-sm-2">CumulativeIdleNonOperatingHours</dt>
            <dd class="col-sm-9">Time where the main motor was on, but pilot control turned off (crane does not move on joystick input when pilot control is turned off)</dd>

            <dt class="col-sm-2">CumulativeLoadCount</dt>
            <dd class="col-sm-9">Amount of cycles completed (material load, material unload)</dd>

            <dt class="col-sm-2">CumulativeOperatingHours</dt>
            <dd class="col-sm-9">Time where the main motor was on</dd>

            <dt class="col-sm-2">CumulativePayloadTotals</dt>
            <dd class="col-sm-9">Cumulative payload mass</dd>

            <dt class="col-sm-2">EngineStatus</dt>
            <dd class="col-sm-9">Whether or not the main motor is turned on or not</dd>

            <dt class="col-sm-2">Location</dt>
            <dd class="col-sm-9">GPS location. The reported altitude is the elevation as reported by Google Maps at the specified GPS coordinates. This data is static</dd>
          </dl>
<!--        <h5>E-Crane extended</h5>
          <dl class="row">
            <dt class="col-sm-3">OilTemperatureTank</dt>
            <dd class="col-sm-9">Temperature of the hydraulic oil in the tank</dd>

            <dt class="col-sm-3">OilTemperatureSplitterBox</dt>
            <dd class="col-sm-9">Temperature of the lubrication oil in the splitterbox</dd>
          </dl>-->

        <h4 id="endpointDocumentation">Per endpoint specific documentation</h4>
          <b-card title="Fleet overview" sub-title-tag="h5" :sub-title="`${apiRoot}/Fleet`" id="fleetOverview">
            <b-card-text>
              This endpoint does not have any parameters
            </b-card-text>
          </b-card>
        <br>
          <b-card title="Single element snapshot" sub-title-tag="h5" :sub-title="`${apiRoot}/Fleet/Equipment/MakeModelSerial/E-Crane/{model}/{serial}`" id="singleElementSnapshot">
            <b-card-text>
              <table class="params">
                <tr>
                  <th colspan="4">Path parameters</th>
                </tr>
                <tr>
                  <td>model</td><td>string</td><td>Required</td>
                  <td>Model of the E-Crane. You can find the model by calling the <a href="#fleetOverview">Fleet overview</a> endpoint</td>
                </tr>
                <tr>
                  <td>serial</td><td>string</td><td>Required</td>
                  <td>Serial of the E-Crane. You can find the serial number by calling the <a href="#fleetOverview">Fleet overview</a> endpoint</td>
                </tr>
              </table>
            </b-card-text>
          </b-card>
        <br>
          <b-card title="Time series" sub-title-tag="h5" :sub-title="`${apiRoot}/Fleet/Equipment/MakeModelSerial/E-Crane/{model}/{serial}/{startDate}/{endDate}`" id="timeSeries">
            <b-card-text>
              <table class="params">
                <tr>
                  <th colspan="4">Path parameters</th>
                </tr>
                <tr>
                  <td>model</td><td>string</td><td>Required</td>
                  <td>Model of the E-Crane. You can find the model by calling the <a href="#fleetOverview">Fleet overview</a> endpoint</td>
                </tr>
                <tr>
                  <td>serial</td><td>string</td><td>Required</td>
                  <td>Serial of the E-Crane. You can find the serial number by calling the <a href="#fleetOverview">Fleet overview</a> endpoint</td>
                </tr>
                <tr>
                  <td>startDate</td><td>string</td><td>Required</td>
                  <td>Start date in ISO 8601 format. Offsets are accepted and honored but the returned timestamps are always in UTC</td>
                </tr>
                <tr>
                  <td>endDate</td><td>string</td><td>Required</td>
                  <td>End date in ISO 8601 format. Offsets are accepted and honored but the returned timestamps are always in UTC</td>
                </tr>
              </table>
            </b-card-text>
          </b-card>
        <br>
        <b-card title="Time series field" sub-title-tag="h5" id="timeSeriesField"
                :sub-title="`${apiRoot}/Fleet/Equipment/MakeModelSerial/E-Crane/{model}/{serial}/{startDate}/{endDate}/{field}/{pageNumber}?perPage={perPage}&step={step}`">
          <b-card-text>
            <table class="params">
              <tr>
                <th colspan="4">Path parameters</th>
              </tr>
              <tr>
                <td>model</td><td>string</td><td>Required</td>
                <td>Model of the E-Crane. You can find the model by calling the <a href="#fleetOverview">Fleet overview</a> endpoint</td>
              </tr>
              <tr>
                <td>serial</td><td>string</td><td>Required</td>
                <td>Serial of the E-Crane. You can find the serial number by calling the <a href="#fleetOverview">Fleet overview</a> endpoint</td>
              </tr>
              <tr>
                <td>startDate</td><td>string</td><td>Required</td>
                <td>Start date in ISO 8601 format. Offsets are accepted and honored but the returned timestamps are always in UTC</td>
              </tr>
              <tr>
                <td>endDate</td><td>string</td><td>Required</td>
                <td>End date in ISO 8601 format. Offsets are accepted and honored but the returned timestamps are always in UTC</td>
              </tr>
              <tr>
                <td>field</td><td>string</td><td>Required</td>
                <td>One of the fields as described in <a href="#fieldDefinitions">field definitions</a></td>
              </tr>
              <tr>
                <td>pageNumber</td><td>number</td><td>Required</td>
                <td>Page number. Pages start at 0</td>
              </tr>
              <tr>
                <th colspan="4">GET query parameters</th>
              </tr>
              <tr>
                <td>perPage</td><td>number</td><td>Optional</td>
                <td>How many results to show per page. Minimum: 10, maximum: 10000, default: 100</td>
              </tr>
              <tr>
                <td>step</td><td>number</td><td>Optional</td>
                <td>Time step in seconds. Minimum: 1, maximum: 2629800, default: 3600</td>
              </tr>
            </table>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { CACHE_AND_NETWORK } from '@/lib/constants';

export default
{
    apollo:
    {
        devices:
        {
            query: require('../../../gql/query/iso15143list.gql'),
            fetchPolicy: CACHE_AND_NETWORK,
            watchLoading(isLoading, mod)
            {
                this.loading = isLoading;
            }
        }
    },
    data()
    {
        const start = new Date();
        start.setDate(start.getDate() - 1);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const end = new Date();
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);

        const tsString  = `${start.toISOString()}/${end.toISOString()}`;
        const root      = `${location.protocol}//${location.host}`;

        return {
            apiKey: 'my_api_key',
            apiRoot: `${root}/api/v1/iso15143`,
            devices: { nodes: [] },
            root,
            tsString,
            loading: true
        }
    }
}
</script>

<style>
table.params tr td:nth-child(1)
{
    color: #e83e8c;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    min-width: 8em;
}

table.params tr td:nth-child(2)
{
    min-width: 5em;
}

table.params tr td:nth-child(3)
{
    font-weight: bold;
    min-width: 6em;
}
</style>